import { render, staticRenderFns } from "./IntegrationChangesModal.vue?vue&type=template&id=09cff058&scoped=true"
import script from "./IntegrationChangesModal.vue?vue&type=script&lang=js"
export * from "./IntegrationChangesModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09cff058",
  null
  
)

export default component.exports