<template>
  <div class="my-flex-container-column">
    <el-row class="m-0" :gutter="20">
      <el-col :span="3">
        <el-button @click="handleModal" type="text"> Запуск интеграции c CK-11
        </el-button>
      </el-col>
      <el-col :span="12">
        <pagination style="text-align: center"
                    :total-count="totalCount"
                    :page-size.sync="pageSize"
                    :current-page.sync="pageNumber"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"/>
      </el-col>
    </el-row>
    <div v-loading="loading" class="my-flex-data-content">
    <el-table style="width: 100%;"
              v-loading="loading"
              :data="integrations"
              :default-sort = "{prop: 'number', order: 'ascending'}"
              @sort-change="handleSortChange">
      <el-table-column label="" width="50">
      </el-table-column>
      <el-table-column prop="number"
                       label="Номер"
                       sortable="custom"
                       min-width="2" /> 
      <el-table-column prop="extNumber"
                       label="Внешний номер"
                       sortable="custom"
                       min-width="7" /> 
      <el-table-column prop="statusName"
                       label="Статус"
                       sortable="custom"
                       min-width="7" />
      <el-table-column prop="startDt"
                       label="Дата начала"
                       sortable="custom"
                       min-width="5" 
                       :formatter="dateTimeRenderer"/>
      <el-table-column prop="endDt"
                       label="Дата окончания"
                       sortable="custom"
                       min-width="5"
                       :formatter="dateTimeRenderer" />
    </el-table>
    </div>
    <integrations-sk11 @cancel="cancelsk11" v-model="modalVisible"></integrations-sk11>
   
  </div>
</template>
<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import formatters from '@/mixins/formatters';
import integrationsApi from '@/api/administration/integrations';
import pagination from '@/components/Ui/Pagination';
import tableResize from '@/mixins/tableResize.js';
import dateHelpers from '@/mixins/dateHelpers';
import IntegrationsSk11 from './Modals/IntegrationsSk11.vue';

export default {
  name: 'IntegrationsJournalTable',
  mixins: [dateHelpers, tableResize, formatters],
  props: {
    filterModel: {
      type: Object,
default: () => {
      }
    },
    filterActive: { type: Boolean, default: () => true },
  },
  components: {
    pagination,
    IntegrationsSk11
  },
  data() {
    return {
      expand: false,
      loading: false,
      integrations: [],
      pageNumber: 1,
      pageSize: 10,
      totalCount: 0,
      sortField: 'startDt',
      sortDesc: true,
      dialogVisible: false,
      dialogPrimaryEquipment: {},
      mergeMode: false,
      mergingItems: [],
      dialogMergeVisible: false,
      linesCount: 0,
      modalVisible: false
    };
  },
  watch: {
    filterModel: {
      handler() {
        // console.log('this.$refs.tree', this.$refs.tree);
        // this.$refs.tree.defaultExpandAll = true;
        this.getIntegrations();
      },
      deep: true
    },
  },
  computed: {
    ...mapGetters('identity', ['userHasRole']),
    userCanEdit() {
      return this.userHasRole('NsiCorpEdit,NsiFullAccess');
    },
  },
  async mounted() {
    await this.getIntegrations();
  },
  methods: {
    async getIntegrations() {
      this.loading = true;
      const params = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        sortField: this.sortField,
        sortDesc: this.sortDesc,
        statuses: this.filterModel.statuses,
        number: this.filterModel.number,
        integrations: this.filterModel.integrations,
        endDtFrom: this.filterModel.periodEndIntegrationTimeRange 
                  ? moment(this.dateSetter(this.filterModel.periodEndIntegrationTimeRange[0])).local().format('YYYY-MM-DD HH:mm:ss') : '',
        endDtTo: this.filterModel.periodEndIntegrationTimeRange 
                  ? moment(this.dateSetter(this.filterModel.periodEndIntegrationTimeRange[1])).local().format('YYYY-MM-DD HH:mm:ss') : '',
        startDtFrom: this.filterModel.periodStartIntegrationTimeRange 
                  ? moment(this.dateSetter(this.filterModel.periodStartIntegrationTimeRange[0])).local().format('YYYY-MM-DD HH:mm:ss') : '',
        startDtTo: this.filterModel.periodStartIntegrationTimeRange 
                  ? moment(this.dateSetter(this.filterModel.periodStartIntegrationTimeRange[1])).local().format('YYYY-MM-DD HH:mm:ss') : '',
      };
      const res = await integrationsApi.getPaging(params);
      if (res.data) {
        this.integrations = res.data.items;
        this.totalCount = res.data.totalCount;
        this.linesCount = res.data.treeItemsCount;
        this.defaultExpandedKeys = this.integrations.map((i) => i.id);

        this.loading = false;
      }
    },
    async handleSizeChange() {
      this.pageNumber = 1;
      await this.getIntegrations();
    },
    async handleCurrentChange() {
      await this.getIntegrations();
    },
    async handleSortChange(event) {
      if (event.order === null) {
        this.sortDesc = null;
        this.sortField = null;
      } else {
        this.sortDesc = event.order === 'descending';
        this.sortField = event.prop;
      }

      await this.getIntegrations();
    },
    handleModal() {
      this.modalVisible = true;
    },
    async handleStart() {
      try {
        this.modalModel.path = this.modalModelPath.value;
        await integrationsApi.getUploadSk11(this.modalModel);
        this.$message.success('Сохранено успешно');
      } catch (e) {
        this.$message.error(e.message);
      } 
    },
    cancelsk11() {
      this.modalVisible = false;
    }
  }
};
</script>

<style scoped>

.total_items {
  float: right;
  color: #909399;
  font-family: Montserrat-Regular;
  font-size: 14px;
  margin-top: 12px;
  margin-right: 8px;
}
</style>
