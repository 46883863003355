<template>
  <el-dialog :visible.sync="dialogVisible" title="Запуск интеграции c CK-11" width="35%" :close-on-click-modal="false">
    <el-form ref="form" :model="modalModel" >
      <el-form-item prop="roleGroupIds" label="Домен:">
        <el-select v-model="modalModelPath" filterable value-key="id" size="medium" style="width:100%;" required>
          <el-option v-for="sk11 in sk11DomainsItems"
                      :key="sk11.id"
                      :label="sk11.name"
                      :value="sk11">
          </el-option>
        </el-select>
      </el-form-item> 
      <el-form-item prop="modelId" label="Модель:">
        <el-select v-model="modalModel.modelId" filterable size="medium" style="width:100%;" required>
          <el-option v-for="model in modelItems"
                      :key="model.modelUid"
                      :label="model.modelDisplayName"
                      :value="model.modelUid">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="roleGroupIds" label="Версия:">
        <el-select v-model="modalModel.modelVersionId" filterable size="medium" style="width:100%;" required>
          <el-option v-for="modelVersion in modelVersionItems"
                      :key="modelVersion.versionId"
                      :label="modelVersion.versionId + ' ' + modelVersion.name"
                      :value="modelVersion.versionId">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-row :gutter="20" type="flex" justify="center">
      <el-col :span="24">
        <div  class="text-right">
          <el-button @click="handleStart" :loading="loading">Запуск</el-button>
        </div>
      </el-col>
    </el-row>
  </el-dialog> 
</template>

<script>
  import { mapGetters } from 'vuex';
  import integrationsApi from '@/api/administration/integrations';

  export default {
    name: '',
    props: {
      value: { type: Boolean, default: () => false },
    },
    data() {
      return {
        modalModel: {
          modelVersionId: null,
          modelId: null,
          path: '',
        },
        modalModelPath: '',
        sk11DomainsItems: [],
        modelItems: [],
        modelVersionItems: [],
        loading: false,
      };
    },
    watch: {
      modalModelPath: {
        async handler(newVal) {
          this.modelVersionItems = [];
          this.modalModel.modelVersionId = null;
          this.modelItems = [];
          this.modalModel.modelId = null;
          if (newVal !== '') {
            const models = await integrationsApi.getModels(newVal.value);
              if (models && models.data) {
              this.modelItems = models.data;
            }
          }
        },
        deep: true
        // },
      },
      'modalModel.modelId': {
        async handler(newVal) {
          this.modelVersionItems = [];
          this.modalModel.modelVersionId = null;
          const modelVersions = await integrationsApi.getModelVersions(newVal, this.modalModelPath.value);
          if (modelVersions && modelVersions.data) {
            this.modelVersionItems = modelVersions.data;
          }
        }
      },
    },
    computed: {
      dialogVisible: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
          if (!val) {
            this.cancel();
          }
        },
      },
      ...mapGetters('identity', ['userHasRole']),
    },
    async mounted() {
      this.modalModel.modelVersionId = null;
      this.modalModel.modelId = null;
      this.modalModel.path = '';
      this.modalModelPath = '';
      const sk11Domains = await integrationsApi.getSk11Domains();
      if (sk11Domains.data) {
        this.sk11DomainsItems = sk11Domains.data;
      }
    },
    methods: {
      cancel() {
        this.$emit('cancel');
      },
      async handleStart() {
        this.loading = true;
        this.$message.success('Запрос на интеграцию отпрален');
        this.cancel();
        try {
          this.modalModel.path = this.modalModelPath.value;
          await integrationsApi.getUploadSk11(this.modalModel);
          this.$message.success('Запрос на интеграцию выполнен успешно');
        } catch (e) {
          this.$message.error(e.message);
        } finally {
          this.loading = false;
        }
      }
    }
  };
</script>
