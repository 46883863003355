<template>
  <el-row :gutter="20" type="flex" justify="center">
      <el-col :span="24">
        <el-form  v-loading="loading" ref="reportForm" 
                 label-width="300px">
          <el-tabs v-model="active_main_tab" type="border-card">
            <el-tab-pane label="Журнал" :style="{ height: cardHeight}" name="journal">
              <integrations-journal></integrations-journal>
            </el-tab-pane>
            <el-tab-pane label="Протокол" :style="{ height: cardHeight}" name="log">
              <integration-changes></integration-changes>
            </el-tab-pane>
            <!-- <el-tab-pane label="Настройки" :style="{ height: cardHeight}" name="log">
            </el-tab-pane> -->
          </el-tabs>
        </el-form>
      </el-col>
    </el-row>
</template>
<script>
import integrationsJournal from '@/views/Administration/Integrations/IntegrationsJournal/IntegrationsJournal';
import integrationChanges from '@/views/Administration/Integrations/IntegrationChanges/IntegrationChanges';
import tableResize from '@/mixins/tableResize.js';
import dateHelpers from '@/mixins/dateHelpers';
import { mapGetters } from 'vuex';

export default {
  name: 'Integration',
  components: {
    integrationsJournal, integrationChanges
  },
  mixins: [dateHelpers, tableResize],
  data() {
    return {
      loading: false,
      active_main_tab: 'journal',
    };
  },

  watch: {
  },
  computed: {
    ...mapGetters('identity', ['isIaLevel', 'userHasRoleGroup'])
  },
  async created() {
    this.loading = true;
    this.cardHeight = this.getCardHeight();
    this.loading = false;
  },
  methods: {
    
  },
};
</script>
