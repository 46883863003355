import axios from 'axios';

// const int = axios.create({
//   baseURL: 'http://localhost:5004/integration',
//   method: 'POST',
//   headers: {
//     'Content-Type': 'application/json'
//   }
// });
export default {
  getPaging(query) {
    return axios.post('/api/Integration/GetPaging', query);
  },
  getChanges(query) {
    return axios.post('/api/Integration/GetChanges', query);
  },
  getChangeItem(changeId) {
    return axios.get(`/api/Integration/GetChange/${changeId}`);
  },
  getSk11Domains() {
    return axios.get('/api/Integration/GetSk11Domains');
  },
  getUploadSk11(data) {
    // return int.post('/api/Sk11/UploadNewModel', data);
    return axios.post('/integration/api/Sk11/UploadNewModel', data);
  },
  getModels(domain) {
    // return int.post('/api/Sk11/GetModels', {
    //   Domain: domain,
    // });
    return axios.post('/integration/api/Sk11/GetModels', {
      Domain: domain,
    });
  },
  getModelVersions(modelId, domain) {
    // return int.post('/api/Sk11/GetModelVersions', {
    //   Domain: domain,
    //   ModelId: modelId
    // });
    return axios.post('/integration/api/Sk11/GetModelVersions', {
      Domain: domain,
      ModelId: modelId
    });
  }
};

